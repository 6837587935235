.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  10%, 90% {
    transform: translate(-1px, 0);
  }
  20%, 80% {
    transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    transform: translate(-4px, 0);
  }
  40%, 60% {
    transform: translate(4px, 0);
  }
}