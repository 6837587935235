:root {
  --black: rgba(0, 0, 0, 1);
  --orange-peel: rgba(247, 163, 42, 1);
  --spanish-orange: rgba(220, 97, 6, 1);
  --harvest-gold: rgba(225, 149, 41, 1);
  --spanish-orange-2: rgba(222, 97, 6, 1);
  --dark-orange: #C05200ff;
  --gradient-top: linear-gradient(0deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-right: linear-gradient(90deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-bottom: linear-gradient(180deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-left: linear-gradient(270deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-top-right: linear-gradient(45deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-bottom-right: linear-gradient(135deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-top-left: linear-gradient(225deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-bottom-left: linear-gradient(315deg, var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
  --gradient-radial: radial-gradient(var(--black), var(--orange-peel), var(--spanish-orange), var(--harvest-gold), var(--spanish-orange-2));
}

/* Estilos para el título h1 */
h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Estilos para el gráfico */
canvas {
  width: 100%;
  max-width: 600px; /* Ajusta el ancho máximo según tu diseño */
  height: auto;
  display: block;
  margin: 20px 0;
}

.pivotTable {
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  white-space: nowrap; /* Evita el salto de línea en el contenido */
}
