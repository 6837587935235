footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: var(--spanish-orange);
}

.footer-container {
  margin-right: 180px;
  position: relative;
}

.footer-shape {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px; 
}

.left-square {
  height: 30px;
  left: 0; 
  background: var(--dark-orange);
}

.left-shape {
  height: 30px;
  left: 60px;
  background: var(--dark-orange);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%); /* Forma de rombo */
}

.right-square {
  height: 30px;
  width: 70px;
  right: -30px; 
  background: var(--harvest-gold);
}

.right-shape {
  clip-path: polygon(100% -170%, 0% 100%, 100% 100%);
  height: 30px;
  right: 30px;
  background: var(--harvest-gold);
}

.etex-logo {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: 60px;
}